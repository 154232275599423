import { useNavigate } from 'react-router-dom';
import { CallToActions } from '../configuration/types';
import { APP_STORE_LINK } from '../../../util/constants';
export const useCTAAction = (action?: CallToActions | undefined) => {
    const navigate = useNavigate();

    const costEstimationAppLink = 'https://simplywise.app.link/MU6gZbLrCMb';
    switch (action) {
        case 'StartForFree':
            return () => navigate('/welcome/sign-up');
        case 'StartForFreeManualCodeEntry':
            return () => navigate('/welcome/sign-up?manualCodeEntry=true');
        case 'TeamsTypeform':
            return () =>
                window.open('https://szs1ainjklw.typeform.com/to/k8B1sZI2');
        case 'TeamsCollectionSignup':
            return () =>
                navigate('/signup?manualCodeEntry=true&variant=texting');
        case 'StartForFreeForSalesTaxOnboarding':
            return () => navigate('/welcome/salestaxonb/quiz');
        case 'CostEstimation':
            return () =>
                navigate(
                    `/cost-estimator?initial-landing=${window.location.pathname}`
                );
        case 'CostEstimationDirectToApp':
            return () => {
                if (process.isServer) return;
                import('../../../providers/generateBranchLink').then((f) => {
                    f.default({
                        data: {
                            branchAppStoreUrl:
                                'https://apps.apple.com/app/simplywise-receipt-scanner/id1538521095?ppid=e78e02eb-b58c-48b8-a142-c7b5f2e8e641',
                            branchPlayStoreUrl:
                                'https://play.google.com/store/apps/details?id=com.simplywise.mobile&listing=estimator',
                            visitor_id: localStorage.getItem('visitor_id'),
                            source: 'cost-estimation',
                            initial_landing: 'direct cost estimation facebook',
                            experience: 'cost-estimation',
                        },
                    }).then((link) => {
                        setTimeout(() => (window.location.href = link), 1000);
                    });
                });
            };
        case 'PhotoAI':
            return () => navigate('/welcome/photo-ai');
        case 'PhotoAIMulti':
            return () => navigate('/welcome/photo-ai-multi');
        case 'PhotoAIProgressReport':
            return () => navigate('/welcome/photo-ai-progress');
        case 'VoiceAI':
            return () => navigate('/welcome/voice-ai');
        case 'DownloadApp':
            return () => window.open(APP_STORE_LINK, '_blank');
        case 'DownloadPage':
            return () => navigate('/download');
        case 'StartTeams':
            return () =>
                navigate('/signup?manualCodeEntry=true&variant=organization');
        case 'Login':
            return () => navigate('/login');
        default:
            return () => navigate('/welcome/sign-up');
    }
};
