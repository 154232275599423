import {
    AccountBalance,
    AccountTree,
    Article,
    AttachMoney,
    CheckCircle,
    CreditCard,
    DateRangeOutlined,
    DirectionsCar,
    EditCalendar,
    Folder,
    LocalGasStation,
    Notes,
    Person,
    PhoneIphone,
    Restaurant,
    Store,
    Subject,
} from '@mui/icons-material';
import theme from '../../theme';
import {
    ISubDocumentFormat,
    SUB_DOCUMENT_FIELDS_MAP,
    SubDocumentFields,
} from '../../util/subDocumentTypes';
import { number } from '../../util/devices';
import { ISpreadsheetData } from './context';

export type ICellCoordinates = {
    row: number;
    col: number;
};

export type ISpreadsheetHistory = {
    receiptId: string;
    column: IColumn;
    prevValue: any;
    prevReceipt: ISpreadsheetData;
    cell: ICellCoordinates;
};

export type InputType =
    | ISubDocumentFormat
    | (
          | 'date'
          | 'boolean'
          | 'blank'
          | 'dropdown'
          | 'modal'
          | 'link'
          | 'readonly'
          | 'subheader'
          | 'selection'
          | 'number'
      );

export interface IExportColumn {
    key: string;
    name: string;
    inputType: InputType;
    fields?: string[]; // only defined for fields on/nested in the receipt object
}

export type IColumn = {
    name: string;
    key: string;
    inputType: InputType;
    width: number;
    // keyof IReceipt OR subdocument formatted like this: "additional_fields.reconciled"
    // only define for fields with scalar values. Else, don't define, as write special updating logic
    fields?: string[];
    isSubDocumentField?: boolean;
    isDefault?: boolean;
    muiIcon?: any;
    disableInInbox?: boolean;
};

export const SPREADSHEET_HEADER_HEIGHT = 87;
export const SPREADSHEET_IMAGE_SECTION_WIDTH = 472;
export const SPREADSHEET_IMAGE_WIDTH = 424;
export const NUM_DEAD_LEFT_COLUMNS = 1;
export const CELL_HEIGHT = 44;
export const CELL_BORDER = `0.5px solid ${theme.colors.blue10}`;
export const CELL_HOVER_OUTLINE = `2px solid ${theme.colors.secondary}`;
export const CELL_PADDING_LEFT = 14;
export const CELL_PADDING_LEFT_HOVERING = 12.5;
export const emptyFunction = () => {};

const SUB_DOCUMENT_FIELDS_TO_MAKE_COLUMNS_FOR = [
    { field: SubDocumentFields.GALLONS, muiIcon: LocalGasStation },
    { field: SubDocumentFields.PRICE_PER_GALLON, muiIcon: LocalGasStation },
    { field: SubDocumentFields.TIP, muiIcon: Restaurant },
    { field: SubDocumentFields.MILES, muiIcon: DirectionsCar },
    { field: SubDocumentFields.FROM_LOCATION, muiIcon: DirectionsCar },
    { field: SubDocumentFields.TO_LOCATION, muiIcon: DirectionsCar },
];

export const COLUMNS: IColumn[] = [
    {
        name: '',
        key: 'selection',
        inputType: 'selection',
        width: CELL_HEIGHT,
        isDefault: true,
    },
    {
        name: 'Date',
        key: 'date',
        inputType: 'date',
        width: 112,
        fields: ['date'],
        isDefault: true,
        muiIcon: DateRangeOutlined,
    },
    {
        name: 'Merchant',
        key: 'merchant',
        inputType: 'string',
        width: 222,
        fields: ['merchant'],
        isDefault: true,
        muiIcon: Store,
    },
    {
        name: 'Folders',
        key: 'folder',
        inputType: 'dropdown',
        width: 144,
        isDefault: true,
        muiIcon: Folder,
    },
    {
        name: 'Payment',
        key: 'payment',
        inputType: 'dropdown',
        width: 112,
        isDefault: true,
        muiIcon: CreditCard,
    },
    {
        name: 'Notes',
        key: 'notes',
        inputType: 'string',
        width: 200,
        fields: ['notes'],
        isDefault: true,
        muiIcon: Notes,
    },
    {
        name: 'Tax',
        key: 'tax',
        inputType: 'dollar',
        width: 110,
        fields: ['secondary_amount'],
        isDefault: true,
        muiIcon: AccountBalance,
    },
    {
        name: 'Total',
        key: 'total',
        inputType: 'dollar',
        width: 110,
        fields: ['amount'],
        isDefault: true,
        muiIcon: AttachMoney,
    },
    {
        name: 'Reconciled',
        key: 'reconciled',
        inputType: 'boolean',
        width: 112,
        fields: ['additional_fields', 'reconciled'],
        isDefault: true,
        muiIcon: CheckCircle,
        disableInInbox: true,
    },
    {
        name: 'Source',
        key: 'platform',
        inputType: 'readonly',
        width: 142,
        fields: ['platform'],
        isDefault: false,
        muiIcon: AccountTree,
    },
    {
        name: 'Employee',
        key: 'employee',
        inputType: 'readonly',
        width: 142,
        fields: ['additional_fields', 'external'],
        isDefault: false,
        muiIcon: PhoneIphone,
    },
    // {
    //     name: 'Link',
    //     key: 'document-link',
    //     inputType: 'link',
    //     width: 92,
    //     isDefault: true,
    // },
    {
        // encompasses document_type AND sub_document_type
        name: 'Doc Type',
        key: 'document_type',
        inputType: 'dropdown',
        width: 112,
        muiIcon: Article,
        // REQUIRES SPECIAL LOGIC
    },
    {
        // encompasses document_type AND sub_document_type
        name: 'Uploaded By',
        key: 'user',
        inputType: 'readonly',
        width: 134,
        fields: ['user', 'first_name'],
        muiIcon: Person,
    },
    {
        // encompasses document_type AND sub_document_type
        name: 'Created At',
        key: 'created_at',
        inputType: 'readonly',
        width: 112,
        fields: ['created_at'],
        muiIcon: EditCalendar,
    },
    {
        // encompasses document_type AND sub_document_type
        name: 'Line Items',
        key: 'line_items',
        inputType: 'modal',
        width: 112,
        muiIcon: Subject,
        // REQUIRES SPECIAL LOGIC
    },
    // {
    //     name: 'Payment Date',
    //     key: 'secondary_date',
    //     inputType: 'date',
    //     width: 144,
    //     fields: ['secondary_date'],
    // },
    // {
    //     // encompasses exchange rate also
    //     name: 'Currency',
    //     key: 'currency',
    //     inputType: 'dropdown',
    //     width: 112,
    //     // REQUIRES SPECIAL LOGIC
    // },
    // SUB DOCUMENT FIELDS
    // TODO: Tip here is like additional_fields.tip.name and .amount
    ...SUB_DOCUMENT_FIELDS_TO_MAKE_COLUMNS_FOR.map(({ field, muiIcon }) => {
        const fieldData = SUB_DOCUMENT_FIELDS_MAP[field];
        return {
            name: fieldData.name,
            key: field,
            inputType: fieldData.format,
            width: Math.max(fieldData.name.length * 10, 96),
            fields: ['additional_fields', field, 'value'],
            isSubDocumentField: true,
            muiIcon,
        };
    }),
];

export const getCustomColumns = (
    indexedData: Map<string, ISpreadsheetData>
) => {
    const dynamicColumns = Array.from(indexedData.values()).reduce(
        (acc: string[], receipt) => {
            if (receipt.type !== 'processing' && receipt.type !== 'stored') {
                return acc;
            }
            Object.keys(receipt.additional_fields || {}).forEach(
                (k: string) => {
                    if (
                        !acc.includes(k) &&
                        ![
                            'reconciled',
                            'polyline_string',
                            'waypoints',
                        ].includes(k)
                    ) {
                        acc.push(k);
                    }
                }
            );
            return acc;
        },
        []
    );
    return dynamicColumns.map(
        (k) =>
            ({
                name: k,
                key: k,
                inputType: 'string',
                width: 200,
                fields: ['additional_fields', k, 'value'],
                isDefault: true,
                muiIcon: Notes,
                disableInInbox: true,
            } as IColumn)
    );
};

export const CELL_STYLE: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    boxSizing: 'border-box', // makes border on inside of cell
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    paddingLeft: CELL_PADDING_LEFT,
    height: CELL_HEIGHT,
    fontSize: 14,
    color: theme.colors.blue100,
    borderLeft: CELL_BORDER,
    borderTop: CELL_BORDER,
    fontWeight: '500',
};

export const getShowImageBesideSpreadsheet = (
    screenWidth: number,
    isDisplayingImage: boolean
) => {
    return isDisplayingImage && screenWidth > number.TABLET + 100;
};
