import { Suspense, lazy, useEffect, useState } from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';
import {
    BrowserRouter,
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import LogRocket from 'logrocket';
import ReactPixel from 'react-facebook-pixel';
import { ENV, FB_PIXEL_ID } from './configuration';
import materialTheme from './theme/materialTheme';
import ServerStateProvider from './providers/ServerStateProvider';
import AmplitudeProvider from './providers/AmplitudeProvider';
import IndexedDBProvider from './providers/IndexedDBProvider';
import AuthProvider from './providers/AuthProvider';
import { SpacesProvider } from './services/spaces/context';
import ReceiptSubscriberProvider from './providers/ReceiptSubscriberProvider';
import FolderProvider from './screens/ReceiptsHome/folderContext';
import { SharingListProvider } from './services/share/context';
import { useLocalStorageItem } from './hooks/useLocalStorageItem';
import { useValidateGuestToken } from './util/hooks';
import ClientMountedHTML from './ClientMountedHTML';
import './index.css';
import Header from './components/Header';
import ScrollToTop from './components/ScrollToTop';
import ExpiredRedirection from './ExpiredRedirection';
import { defineAllVariations } from './components/LandingPageVariations/routes/defineVariations';
import { generateVariationsRoutes } from './components/LandingPageVariations/routes/generateVariationsRoutes';
import { landingPageVariationsConfig } from './landingPageVariationsConfig';
import { LandingPageVariation } from './components/LandingPageVariations/LandingPageVariation';
import ChargeFinder from './screens/Onboarding/ChargeFinder';
import ChargeFinderMerchant from './screens/Onboarding/ChargeFinder/Merchant';
import ChargeFinderMerchantCode from './screens/Onboarding/ChargeFinder/Code';
import TypeformSuccess from './screens/Organization/TypeformSuccess';
import theme from './theme';
import SnackbarProvider from './providers/SnackbarProvider';
import FullScreenModalProvider from './providers/FullScreenModalProvider';
import { InboxProvider } from './screens/Inbox/context';
import Integrations from './screens/Integrations';
import ReconciliationLandingVariation3 from './screens/ReconciliationLanding/Variation3';
import ReconciliationLandingVariation4 from './screens/ReconciliationLanding/Variation4';
import ReconciliationFlow from './screens/ReconciliationLanding/ReconciliationFlow';
import TaxSavingsReport from './screens/TaxSavingsReport';
import RedirectToTeamsPayment from './screens/Organization/Payment/RedirectToTeamsPayment';
import CostEstimation from './screens/CostEstimation';
import CodeChecker from './screens/CodeChecker';
import PhotoAI from './screens/PhotoAI';
import PhotoAIMulti from './screens/PhotoAIMulti';
import PhotoAIProgressReport from './screens/PhotoAIProgressReport';
import { BrowserDetailsProvider } from './services/browserDetails/context';
import CostEstimator from './screens/CostEstimator';

const Receipts = lazy(() => import('./screens/Receipts'));
const StandaloneReceipt = lazy(
    () => import('./screens/Receipts/StandaloneReceipt')
);
const Inbox = lazy(() => import('./screens/Inbox'));
const Report = lazy(() => import('./screens/Report'));
const ReportLoading = lazy(
    () => import('./screens/Report/components/ReportLoading')
);
const ReportStandaloneImage = lazy(
    () => import('./screens/Report/components/ReportStandaloneImage')
);
const DynamicLink = lazy(() => import('./screens/DynamicLink'));
const ShareAccept = lazy(() => import('./screens/DynamicLink/ShareAccept'));
const SharingListMemberDetails = lazy(
    () => import('./screens/SharingList/SharingListMemberDetails')
);
const SharingListAddMember = lazy(
    () => import('./screens/SharingList/SharingListAddMember')
);
const SharingListSelectFolders = lazy(
    () => import('./screens/SharingList/SharingListSelectFolders')
);
const SharingListAddConfirmation = lazy(
    () => import('./screens/SharingList/SharingListAddConfirmation')
);
const SharingListRemoveConfirmation = lazy(
    () => import('./screens/SharingList/SharingListRemoveConfirmation')
);
const OrganizationAccept = lazy(
    () => import('./screens/DynamicLink/OrganizationAccept')
);
const ExportProvider = lazy(() => import('./screens/Receipts/Export/context'));
const ReportPasscode = lazy(
    () => import('./screens/Report/components/ReportPasscode')
);
const ReportError = lazy(
    () => import('./screens/Report/components/ReportError')
);
const OnboardingFlow = lazy(
    () => import('./screens/Onboarding/OnboardingFlow')
);
const ReconciliationOnboarding = lazy(
    () => import('./screens/Onboarding/ReconciliationOnboarding')
);
const Invoice = lazy(() => import('./screens/Onboarding/Invoice'));
const SimplifiedSalesTax = lazy(
    () => import('./screens/Onboarding/SimplifiedSalesTax')
);
const Download = lazy(() => import('./screens/Download'));
const RedirectDownload = lazy(() => import('./screens/Download/Redirect'));
const RedirectUrl = lazy(() => import('./screens/Landing/Redirect'));
const Terms = lazy(() => import('./screens/Terms'));
const About = lazy(() => import('./screens/About'));
const Help = lazy(() => import('./screens/Help'));
const Login = lazy(() => import('./screens/Login'));
const PaymentCancel = lazy(() => import('./screens/Payment/cancel'));
const PaymentSuccess = lazy(() => import('./screens/Payment/success'));
const Privacy = lazy(() => import('./screens/Privacy'));
const FAQList = lazy(() => import('./screens/FAQ/FAQList'));
const ReceiptsHome = lazy(() => import('./screens/ReceiptsHome'));
const Reports = lazy(() => import('./screens/Reports'));
const CreateReport = lazy(() => import('./screens/Reports/CreateReport'));
const Settings = lazy(() => import('./screens/Settings'));
const BankReconciliation = lazy(() => import('./screens/BankReconciliation'));
const MileageTracking = lazy(() => import('./screens/MileageTracking'));
const Subscription = lazy(() => import('./screens/Settings/Subscription'));
const Subscriptions = lazy(() => import('./screens/Payment/Subscriptions'));
const SharingList = lazy(
    () => import('./screens/SharingList/SharingListMembers')
);
const OrganizationLanding = lazy(
    () => import('./screens/Organization/Landing')
);
const OrganizationPlan = lazy(() => import('./screens/Organization/Plan'));
const OrganizationPaymentLanding = lazy(
    () => import('./screens/Organization/Payment')
);
const OrganizationWelcome = lazy(
    () => import('./screens/Organization/Welcome')
);
const OrganizationMembers = lazy(
    () => import('./screens/Settings/Organization/Members')
);
const OrganizationSettings = lazy(
    () => import('./screens/Settings/Organization/Settings')
);
const Pacman = lazy(() => import('./screens/Pacman'));
const Texting = lazy(() => import('./screens/Texting'));

const Support = lazy(() => import('./screens/Support'));

const SharingListFolderMembers = lazy(
    () => import('./screens/SharingList/SharingListFolderMembers')
);

const SharingListFolderMemberSuggestion = lazy(
    () => import('./screens/SharingList/SharingListFolderMemberSuggestion')
);
const VoiceAI = lazy(() => import('./screens/VoiceAI'));

defineAllVariations(landingPageVariationsConfig);

const welcomePageConfig = landingPageVariationsConfig.find(
    (config) => config.path === 'updated_home_page'
);

const WelcomeLandingPage = () =>
    welcomePageConfig ? <LandingPageVariation {...welcomePageConfig} /> : null;

const ProtectedHomePage = () => {
    let persistToken: string | null = localStorage.getItem('token');

    const navigate = useNavigate();

    useEffect(() => {
        if (persistToken) {
            navigate('/app');
        }
    }, [persistToken]);

    return <WelcomeLandingPage />;
};

// The -er pattern is for the component which are shared between client and server but
// we intend to keep the component orders the same therefore we use the `process.isServer` condition for them

const Router = ({ children }: { children: JSX.Element }) => {
    if (process.isServer) return children;
    return <BrowserRouter>{children}</BrowserRouter>;
};

const ServerStater = ({ children }: { children: JSX.Element }) => {
    if (process.isServer) return children;
    return <ServerStateProvider>{children}</ServerStateProvider>;
};

const Helmeter = ({ children }: { children: JSX.Element }) => {
    if (process.isServer) return children;
    return <HelmetProvider>{children}</HelmetProvider>;
};

const Themer = ({ children }: { children: JSX.Element }) => {
    if (process.isServer) return children;
    return <ThemeProvider theme={materialTheme}>{children}</ThemeProvider>;
};

const Suspenser = ({ children }: { children: JSX.Element }) => {
    if (process.isServer) return <Suspense>{children}</Suspense>;
    return (
        <Suspense
            fallback={
                <div
                    style={{
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress color={'primary'} />
                </div>
            }
        >
            {children}
        </Suspense>
    );
};

const ProtectedRoute = ({ children }: any) => {
    let persistToken: string | null = localStorage.getItem('token');

    const location = useLocation();

    if (persistToken === null || persistToken === '') {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    return children;
};

const ProtectedGuestRoute = ({ children }: any) => {
    const { isValid, isFetching, isError } = useValidateGuestToken();
    const [searchParams] = useSearchParams();
    const sourceIsMobileAppParam = searchParams.get('sourceIsMobileApp');
    const sourceIsMobileApp = sourceIsMobileAppParam === 'true';

    const { id } = useParams();
    const location = useLocation();

    const fallback = sourceIsMobileApp ? (
        <>
            <div
                style={{
                    height: '100vh',
                    width: '100%',
                    backgroundColor: theme.colors.background,
                }}
            />
        </>
    ) : (
        <>
            <Header />
            <div
                style={{
                    height: '100vh',
                    width: '100%',
                    backgroundColor: theme.colors.background,
                }}
            />
        </>
    );

    if (isFetching) {
        return fallback;
    }

    if (isError) {
        return (
            <Navigate
                to={`/shared-review/${id}/error`}
                replace
                state={{ from: location }}
            />
        );
    }

    if (!isValid) {
        return (
            <Navigate
                to={`/shared-review/${id}/pin`}
                replace
                state={{ from: location }}
            />
        );
    }

    return <Suspense fallback={fallback}>{children}</Suspense>;
};

const FacebookPixelInitializer = () => {
    let generalAttributionId = localStorage.getItem('general_attribution_id');
    if (!generalAttributionId) {
        generalAttributionId = uuidv4();
        localStorage.setItem('general_attribution_id', generalAttributionId);
    }
    useEffect(() => {
        if (FB_PIXEL_ID) {
            ReactPixel.init(FB_PIXEL_ID, {
                // @ts-ignore
                external_id: generalAttributionId,
            });
        }
        ReactPixel.pageView();
    }, []);

    return null;
};

const LogRocketInitializer = () => {
    const location = useLocation();
    const originWebUrl = useLocalStorageItem('originWebUrl');

    const [logRocketInitialized, setLogRocketInitialized] = useState(false);

    const logrocketedRoutes = [
        'teams',
        'sales-tax-calculator',
        'invoice',
        'lp-facebook/reconciliation',
        'lp-google/reconciliation',
        'lp-chargefinder/reconciliation',
        'lp-facebook/teams-texting',
        'lp-google/teams-texting',
        'lp-facebook/cost-estimator',
        'lp-google/cost-estimator',
        'lp-reddit/cost-estimator',
    ];

    const routesIncluded = logrocketedRoutes.some((route) =>
        location.pathname.includes(route)
    );

    useEffect(() => {
        if (
            ENV === 'production' &&
            // We only want to initialize LogRocket in production and for specific routes
            (originWebUrl || routesIncluded) &&
            !logRocketInitialized
        ) {
            LogRocket.init('simplywise/simplywise_web_onboarding', {
                rootHostname: 'simplywise.com',
            });
            setLogRocketInitialized(true);
        }
    }, [location, originWebUrl, logRocketInitialized]);

    return null;
};

export default function Index() {
    return (
        <Themer>
            <SnackbarProvider>
                <FullScreenModalProvider>
                    <Router>
                        <ServerStater>
                            <AmplitudeProvider>
                                <AuthProvider>
                                    <IndexedDBProvider>
                                        <FolderProvider>
                                            <SpacesProvider>
                                                <BrowserDetailsProvider>
                                                    <SharingListProvider>
                                                        <ReceiptSubscriberProvider>
                                                            <ScrollToTop />
                                                            <ClientMountedHTML />
                                                            <ExpiredRedirection />
                                                            <FacebookPixelInitializer />
                                                            <LogRocketInitializer />
                                                            <Helmeter>
                                                                <>
                                                                    <Helmet>
                                                                        <title>
                                                                            SimplyWise
                                                                            |
                                                                            Receipt
                                                                            Scanner
                                                                        </title>
                                                                        <meta
                                                                            name="description"
                                                                            content="Scan and organize receipts, bills, invoices, and important paperwork in one place. File and find your receipts and documents quickly."
                                                                        />
                                                                        <link
                                                                            rel="canonical"
                                                                            href="https://www.simplywise.com"
                                                                        />
                                                                    </Helmet>
                                                                    <Suspenser>
                                                                        <div>
                                                                            <Routes>
                                                                                <Route
                                                                                    path="/"
                                                                                    element={
                                                                                        <ProtectedHomePage />
                                                                                    }
                                                                                />
                                                                                {generateVariationsRoutes(
                                                                                    landingPageVariationsConfig,
                                                                                    'lp-'
                                                                                )}
                                                                                <Route
                                                                                    path="help"
                                                                                    element={
                                                                                        <Help />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="about"
                                                                                    element={
                                                                                        <About />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="privacy"
                                                                                    element={
                                                                                        <Privacy />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="terms"
                                                                                    element={
                                                                                        <Terms />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="faq"
                                                                                    element={
                                                                                        <FAQList />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path={
                                                                                        'invoice'
                                                                                    }
                                                                                    element={
                                                                                        <Invoice />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path={
                                                                                        'sales-tax-calculator'
                                                                                    }
                                                                                    element={
                                                                                        <SimplifiedSalesTax />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="whats-this-charge"
                                                                                    element={
                                                                                        <ChargeFinder />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="whats-this-charge/:merchant"
                                                                                    element={
                                                                                        <ChargeFinderMerchant />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="lp-facebook/reconciliation"
                                                                                    element={
                                                                                        <ReconciliationLandingVariation3 />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="lp-google/reconciliation"
                                                                                    element={
                                                                                        <ReconciliationLandingVariation3 />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="lp-chargefinder/reconciliation"
                                                                                    element={
                                                                                        <ReconciliationLandingVariation4 />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="whats-this-charge/:merchant/:code"
                                                                                    element={
                                                                                        <ChargeFinderMerchantCode />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="teams"
                                                                                    element={
                                                                                        <OrganizationLanding />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="teams/plan"
                                                                                    element={
                                                                                        <OrganizationPlan />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="teams/invite"
                                                                                    element={
                                                                                        <RedirectToTeamsPayment />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="teams/typeform-success"
                                                                                    element={
                                                                                        <TypeformSuccess />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="teams/payment"
                                                                                    element={
                                                                                        <OrganizationPaymentLanding />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="teams/payment/:orderId"
                                                                                    element={
                                                                                        <OrganizationPaymentLanding />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="teams/welcome"
                                                                                    element={
                                                                                        <OrganizationWelcome />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="settings"
                                                                                    element={
                                                                                        <ProtectedRoute>
                                                                                            <Settings />
                                                                                        </ProtectedRoute>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="settings/subscriptions"
                                                                                    element={
                                                                                        <ProtectedRoute>
                                                                                            <Subscriptions />
                                                                                        </ProtectedRoute>
                                                                                    }
                                                                                />
                                                                                {/** TODO: REMOVE */}
                                                                                <Route
                                                                                    path="settings/subscriptions/old"
                                                                                    element={
                                                                                        <ProtectedRoute>
                                                                                            <Subscription />
                                                                                        </ProtectedRoute>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="settings/teams/members"
                                                                                    element={
                                                                                        <ProtectedRoute>
                                                                                            <OrganizationMembers />
                                                                                        </ProtectedRoute>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="settings/teams/organization"
                                                                                    element={
                                                                                        <ProtectedRoute>
                                                                                            <OrganizationSettings />
                                                                                        </ProtectedRoute>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="login"
                                                                                    element={
                                                                                        <Login
                                                                                            screen="login"
                                                                                            key="login"
                                                                                        />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="signup"
                                                                                    element={
                                                                                        <Login
                                                                                            screen="signup"
                                                                                            key="signup"
                                                                                        />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path={
                                                                                        'welcome/sign-up'
                                                                                    }
                                                                                    element={
                                                                                        <OnboardingFlow />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path={
                                                                                        'reconciliation/sign-up'
                                                                                    }
                                                                                    element={
                                                                                        <ReconciliationOnboarding />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path={
                                                                                        'welcome/sign-up/*'
                                                                                    }
                                                                                    element={
                                                                                        <Navigate to="/welcome/sign-up" />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path={
                                                                                        'welcome/cost-estimation'
                                                                                    }
                                                                                    element={
                                                                                        <CostEstimation />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path={
                                                                                        'cost-estimator'
                                                                                    }
                                                                                    element={
                                                                                        <CostEstimator />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path={
                                                                                        'welcome/code-checker'
                                                                                    }
                                                                                    element={
                                                                                        <CodeChecker />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path={
                                                                                        'welcome/photo-ai'
                                                                                    }
                                                                                    element={
                                                                                        <PhotoAI />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path={
                                                                                        'welcome/photo-ai-multi'
                                                                                    }
                                                                                    element={
                                                                                        <PhotoAIMulti />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path={
                                                                                        'welcome/photo-ai-progress'
                                                                                    }
                                                                                    element={
                                                                                        <PhotoAIProgressReport />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="welcome/voice-ai"
                                                                                    element={
                                                                                        <VoiceAI />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="payment"
                                                                                    element={
                                                                                        <Navigate to="/welcome/sign-up?payment=true" />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="payment/success"
                                                                                    element={
                                                                                        <PaymentSuccess />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="payment/cancel"
                                                                                    element={
                                                                                        <PaymentCancel />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="download/*"
                                                                                    element={
                                                                                        <Download />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="redirect-download"
                                                                                    element={
                                                                                        <RedirectDownload />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="redirect-url"
                                                                                    element={
                                                                                        <RedirectUrl />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="link/:linkToken"
                                                                                    element={
                                                                                        <DynamicLink />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="share/accept/:linkToken"
                                                                                    element={
                                                                                        <ShareAccept />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="organization/accept/:linkToken"
                                                                                    element={
                                                                                        <OrganizationAccept />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="share/folder-members"
                                                                                    element={
                                                                                        <SharingListFolderMembers />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="share/folder-member-suggestion"
                                                                                    element={
                                                                                        <SharingListFolderMemberSuggestion />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="app"
                                                                                    element={
                                                                                        <ProtectedRoute>
                                                                                            <ExportProvider>
                                                                                                <InboxProvider>
                                                                                                    <ReceiptsHome />
                                                                                                </InboxProvider>
                                                                                            </ExportProvider>
                                                                                        </ProtectedRoute>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="receipts"
                                                                                    element={
                                                                                        <ProtectedRoute>
                                                                                            <ExportProvider>
                                                                                                <Receipts />
                                                                                            </ExportProvider>
                                                                                        </ProtectedRoute>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="receipts/:id"
                                                                                    element={
                                                                                        <ProtectedRoute>
                                                                                            <StandaloneReceipt />
                                                                                        </ProtectedRoute>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="inbox"
                                                                                    element={
                                                                                        <ProtectedRoute>
                                                                                            <Inbox />
                                                                                        </ProtectedRoute>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="share"
                                                                                    element={
                                                                                        <ProtectedRoute>
                                                                                            <SharingList />
                                                                                        </ProtectedRoute>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="share/member/:id"
                                                                                    element={
                                                                                        <ProtectedRoute>
                                                                                            <SharingListMemberDetails />
                                                                                        </ProtectedRoute>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="share/add-member"
                                                                                    element={
                                                                                        <ProtectedRoute>
                                                                                            <SharingListAddMember />
                                                                                        </ProtectedRoute>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="share/select-folders"
                                                                                    element={
                                                                                        <ProtectedRoute>
                                                                                            <SharingListSelectFolders />
                                                                                        </ProtectedRoute>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="share/add-confirm"
                                                                                    element={
                                                                                        <ProtectedRoute>
                                                                                            <SharingListAddConfirmation />
                                                                                        </ProtectedRoute>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="share/remove-confirm"
                                                                                    element={
                                                                                        <ProtectedRoute>
                                                                                            <SharingListRemoveConfirmation />
                                                                                        </ProtectedRoute>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="mileage"
                                                                                    element={
                                                                                        <ProtectedRoute>
                                                                                            <MileageTracking />
                                                                                        </ProtectedRoute>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="integrations"
                                                                                    element={
                                                                                        <ProtectedRoute>
                                                                                            <Integrations />
                                                                                        </ProtectedRoute>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="plaid"
                                                                                    element={
                                                                                        <ProtectedRoute>
                                                                                            <BankReconciliation />
                                                                                        </ProtectedRoute>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="reconciliation-flow"
                                                                                    element={
                                                                                        <ProtectedRoute>
                                                                                            <ReconciliationFlow />
                                                                                        </ProtectedRoute>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="tax-savings"
                                                                                    element={
                                                                                        <TaxSavingsReport />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="reports"
                                                                                    element={
                                                                                        <ProtectedRoute>
                                                                                            <Reports />
                                                                                        </ProtectedRoute>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="reports/create"
                                                                                    element={
                                                                                        <ExportProvider>
                                                                                            <CreateReport />
                                                                                        </ExportProvider>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="shared-review/:id/pin"
                                                                                    element={
                                                                                        <ReportPasscode />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="shared-review/:id/error"
                                                                                    element={
                                                                                        <ReportError />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="shared-review/loading"
                                                                                    element={
                                                                                        <ReportLoading />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="shared-review/:id"
                                                                                    element={
                                                                                        <ProtectedGuestRoute>
                                                                                            <Report />
                                                                                        </ProtectedGuestRoute>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="shared-review/:id/receipts/:receiptId"
                                                                                    element={
                                                                                        <ProtectedGuestRoute>
                                                                                            <ReportStandaloneImage />
                                                                                        </ProtectedGuestRoute>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="shared-review-v2/:id"
                                                                                    element={
                                                                                        <ProtectedGuestRoute>
                                                                                            <Report />
                                                                                        </ProtectedGuestRoute>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="texting"
                                                                                    element={
                                                                                        <Texting />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="pacman"
                                                                                    element={
                                                                                        <Pacman />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="support"
                                                                                    element={
                                                                                        <Support />
                                                                                    }
                                                                                />
                                                                                {/* <Route
                                                                                path="*"
                                                                                element={
                                                                                    <Navigate to="/" />
                                                                                }
                                                                            /> */}
                                                                                <Route
                                                                                    path="*"
                                                                                    element={
                                                                                        <DynamicLink />
                                                                                    }
                                                                                />
                                                                            </Routes>
                                                                        </div>
                                                                    </Suspenser>
                                                                </>
                                                            </Helmeter>
                                                        </ReceiptSubscriberProvider>
                                                    </SharingListProvider>
                                                </BrowserDetailsProvider>
                                            </SpacesProvider>
                                        </FolderProvider>
                                    </IndexedDBProvider>
                                </AuthProvider>
                            </AmplitudeProvider>
                        </ServerStater>
                    </Router>
                </FullScreenModalProvider>
            </SnackbarProvider>
        </Themer>
    );
}

if (!process.isServer) {
    if (window?.ssr) {
        hydrateRoot(document.getElementById('root') as HTMLElement, <Index />);
    } else {
        createRoot(document.getElementById('root') as HTMLElement).render(
            <Index />
        );
    }
}

// @ts-ignore
if (module.hot) {
    // @ts-ignore
    module.hot.accept();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
